<template>
  <v-container>
    <v-row >
      <v-col cols="2">
        <h2>冷媒一覧</h2>
      </v-col>
      <v-col cols="3" class="mt-3">
        <v-text-field
          label="冷媒名"
          v-model="searchString"
          dense
          :readonly="false"
          :clearable="false"
          :rules="[maxLength(50)]"
          :loading="loading"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" class="mt-3">
        <v-btn :width="100" class="primary" v-on:click="cancel()" :disabled="loading || !edit">キャンセル</v-btn>
        <v-btn :width="100" class="primary ml-5" v-on:click="regist()" :disabled="loading || !edit">保存</v-btn>
        <v-btn :width="100" class="primary ml-5" v-on:click="addItem()" :disabled="loading"><v-icon left>mdi-plus-circle</v-icon>追加</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="refList"
      :loading="loading"
      dense
      :mobile-breakpoint="0"
      :disable-pagination='true'
      :hide-default-footer='true'
      :fixed-header='true'
      height="calc(100vh - 200px)"
      disable-sort
      locale="ja-jp"
      loading-text="読込中"
      no-data-text="データがありません。"
      class=""
      :search="searchString"
    >
      <template v-slot:body="{items}">
        <tbody v-model="refList" is="draggable" tag="tbody" @end="draggableEnd">
          <tr v-for="(item, index) in items" :key="index" :class="[(item.disp == 0) ? 'disable-color' : '']">
            <td>
              {{index+1}}
            </td>
            <td>
              <v-text-field
                v-model="item.refrigerant_name"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[]"
                :maxLength="50"
                :loading="loading"
                hide-details
              ></v-text-field>
            </td>
            <td>
              <v-btn v-if="item.disp == 1" small class="secondary" v-on:click="disableItem(index)" :disabled="loading" :height="20" :width="100"><v-icon small>mdi-eye-off</v-icon>非表示</v-btn>
              <v-btn v-else small class="primary" v-on:click="enableItem(index)" :disabled="loading" :height="20" :width="100"><v-icon small>mdi-eye</v-icon>表示</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

  </v-container>

</template>

<script>
import draggable from 'vuedraggable'

import Mrefrigerant from '../../model/m_refrigerant'

import Const from '../../mixins/const';
import Message from '../../Lib/Message';
import Debug from '../../Lib/Debug';

export default {
  components: {
    draggable,
  },

  mixins: [Const],

  data: () => ({
    loading: false,

    headers: [
      { text: 'No', align: 'left', sortable: true, value: 'index', width: "10%" },
      { text: '冷媒名', align: 'left', sortable: true, value: 'refrigerant_name', width: "20%" },
      { text: '', align: 'center', sortable: false, value: 'action', width: "70%" },
    ],

    refList: [],

    searchCondition: {
      screenType: 'MrefrigerantList',
      enable: false,
    },

    searchString: "",

    // edit用
    edit: false,
  }),

  created: function() {
  },

  mounted : function() {
    // 検索条件 を読み込む
    const storeSearchCondition = this.$store.getters.getStoreSearchCondition;
    if (storeSearchCondition.screenType == 'MrefrigerantList') {
      this.searchCondition = storeSearchCondition;
    }
    Debug.log2("searchCondition", this.searchCondition);

    this.loadList();
  },

  methods: {
    async loadList() {
      Debug.log('function[loadList]');

      this.loading = true
      // 冷媒一覧を取得
      Mrefrigerant.search(this.searchCondition)
        .then(res => {
          if (res && res.data) {
            this.refList = res.data;

            Debug.log2("userList->", this.items);

            // 検索条件を保存
            this.$store.commit('setStoreSearchCondition', this.searchCondition);
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[冷媒一覧データ]');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 冷媒の追加
    addItem() {
      // 編集フラグtrue
      if(!this.edit) {
        this.edit = true;
      }

      // 最後の表示順を取得
      const lastOrderNo = this.refList.slice(-1)[0].order_no;
      
      // 追加アイテム
      const tmpItem = {
        sid: null,
        refrigerant_name: "",
        order_no: lastOrderNo + 1,
        disp: 1,
      };

      // リストの最後に追加
      this.refList.push(tmpItem);

      Debug.log2('addItem', this.refList);
    },

    // 冷媒の非表示
    disableItem(index) {
      // 編集フラグtrue
      if(!this.edit) {
        this.edit = true;
      }

      // インデックスのアイテムを非表示に変更
      let tmpItem = this.refList[index];
      tmpItem.disp = 0;
      this.refList.splice(index, 1, tmpItem);
    },

    // 冷媒の表示
    enableItem(index) {
      // 編集フラグtrue
      if(!this.edit) {
        this.edit = true;
      }

      // インデックスのアイテムを表示に変更
      let tmpItem = this.refList[index];
      tmpItem.disp = 1;
      this.refList.splice(index, 1, tmpItem);
    },

    cancel() {
      Debug.log('function[cancel]');
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;

      // 編集フラグfalse
      if(this.edit) {
        this.edit = false;
      }

      this.loadList();
    },

    async regist() {
      Debug.log2('regist:', this.refList);

      if (!confirm("冷媒一覧を保存します。よろしいですか？")) return;

      this.loading = true;
      try {
        const res = await Mrefrigerant.updateData(this.refList);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }

      } catch (error) {
        Message.err(error, "保存できませんでした");
      }
      this.loading = false;

      this.loadList();
    },

    // /** テーブルドラッグ */
    draggableEnd() {
      // 編集フラグtrue
      if(!this.edit) {
        this.edit = true;
      }

      // Noをふりなおす
      for (let i = 0; i < this.refList.length; i++) {
        this.refList[i]["order_no"] = i + 1;
      }

    },
  },

  computed: {
  }

}
</script>


<style scoped>

</style>
