import Axios from "axios";
import token from "./setToken";

export default {
  async updateData(refList) {

    let params = new URLSearchParams();
    params.append('refList', JSON.stringify(refList));

    try {
      const res = await Axios.post('/api/Mrefrigerant', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      // エラーログを出力して、再スロー
      console.error("データを更新できません[updateData]");
      throw error;
    }
  }, 

  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MrefrigerantList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("冷媒一覧を取得できません[search]");
      throw error;
    }
  },

  async selectList() {
    let params = new URLSearchParams();

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MrefrigerantSelectList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("冷媒一覧を取得できません[selectList]");
      throw error;
    }
  },

};
